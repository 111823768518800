<template>
  <div>
    <el-row>
      <el-col :span="12">
        <el-form label-width="130px">
          <el-form-item label="分佣金额">
            <el-input-number :min="0" v-model="form.award_base_fee"></el-input-number>
          </el-form-item>
          <template v-if="form.type === 2">
            <el-form-item label="代理直推分佣比例">
              <el-input-number :min="0"  v-model="form.upgrade_award_agent_scale"></el-input-number>%
            </el-form-item>
            <el-form-item label="老板直推分佣比例">
              <el-input-number :min="0" v-model="form.upgrade_award_boss_scale"></el-input-number>%
            </el-form-item>
          </template>
          <template v-if="form.type === 1">
            <el-form-item label="直推分佣比例">
              <el-input-number :min="0"  v-model="form.invite_award_scale"></el-input-number>%
            </el-form-item>
            <el-form-item label="间推分佣比例">
              <el-input-number :min="0" v-model="form.invite_2_award_scale"></el-input-number>%
            </el-form-item>
          </template>
          <el-form-item>
            <el-button @click="submit" type="primary">提交</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "award",
  model:{
    prop:"modelVal",
    event:'change'
  },
  props:{
    modelVal:{
      type:Object,
      default(){
        return{}
      }
    },
  },
  watch:{
    modelVal(){
      this.form = this.modelVal;
    },
  },
  data(){
    return{
      form:this.modelVal,
    }
  },
  mounted() {

  },
  methods:{
    submit(){
      this.$emit('form-submit');
    },
  }
}
</script>

<style scoped>

</style>