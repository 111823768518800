<template>
  <div>
    <el-row>
      <el-col :span="12">
        <el-form label-width="130px">
          <el-form-item label="消费盲返">
            <el-switch v-model="form.enable"></el-switch>
          </el-form-item>
          <el-form-item label="盲返比例">
            <el-input-number v-model="form.ratio"></el-input-number>%
          </el-form-item>
          <el-form-item>
            <el-button @click="submit" type="primary">提交</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "blindAward",
  props:{
    goodsId:{
      type:Number,
      default:0,
    },
  },
  watch:{
    goodsId:{
      handler:"goodsIdChanged",
      immediate:true,
      deep:true,
    }
  },
  data(){
    return{
      form: {
        enable:false,
        ratio:0,
      },
    }
  },
  mounted() {

  },
  methods:{
    submit(){
      this.$u.api.shop.plugin.blindAward.goodsConfEdit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    load(){
      if (this.goodsId <= 0)return;
      this.$u.api.shop.plugin.blindAward.goodsConf({
        id:this.goodsId
      }).then(res=>{
        this.form = res;
      })
    },
    goodsIdChanged(){
      this.load();
    },
  }
}
</script>

<style scoped>

</style>