<template>
  <div>
    <el-table :data="list">
      <el-table-column width="80" prop="shareholder_id" label="编号"></el-table-column>
      <el-table-column label="股东昵称" prop="nickname" show-overflow-tooltip></el-table-column>
      <el-table-column label="分红" prop="award">
        <template #default="s">
          <el-input-number @change="inputChange($event,s.$index)" v-model="list[s.$index].award"></el-input-number>元
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>

export default {
  name: "partnerShareholderGoodsAward",
  model: {
    prop: "modelVal",
    event: 'change'
  },
  props: {
    modelVal: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  watch: {
    modelVal() {
      this.form = this.modelVal;
    },
  },
  data() {
    return {
      form: this.modelVal,
      list: [],
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load(){
      this.$u.api.shop.plugin.partnership.shareholderGoodsAward({goods_id: this.form.id}).then(res => {
        this.list = res;
      })
    },
    inputChange(e,index){
      this.$u.api.shop.plugin.partnership.shareholderGoodsAwardEdit({
        id:this.list[index].id,
        award:e,
      }).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    submit() {
      this.$emit('form-submit', this.form.id > 0);
    },
    pageChange(e) {
      this.page = e;
      this.searchGo();
    },
  }
}
</script>

<style scoped>

</style>