<template>
  <div>
    <el-form label-width="130px" inline>
      <el-form-item label="卡密发放">
        <el-switch v-model="form.grant_cdkey"></el-switch>
      </el-form-item>
      <el-form-item>
        <el-button @click="submit" size="mini" type="primary">保存</el-button>
      </el-form-item>
    </el-form>
    <template v-if="form.id > 0 && form.grant_cdkey">
      <div>
        <el-form label-width="90px" inline>
          <el-form-item label="卡号">
            <el-input v-model="searchForm.no" size="mini" style="width: 100px"></el-input>
          </el-form-item>
          <el-form-item label="用户昵称">
            <el-input v-model="searchForm.nickname" size="mini" style="width: 100px;"></el-input>
          </el-form-item>
          <el-form-item label="用户编号">
            <el-input-number v-model="searchForm.uid" size="mini" style="width: 100px"></el-input-number>
          </el-form-item>
          <el-form-item label="状态">
            <el-select v-model="searchForm.grant_state" size="mini" style="width: 100px">
              <el-option label="全部" :value="0"></el-option>
              <el-option label="未发放" :value="1"></el-option>
              <el-option label="已发放" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label=" ">
            <el-button @click="searchGo" type="primary" size="mini">搜索</el-button>
            <el-button @click="searchReset" size="mini">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="list">
      <el-table-column width="80" prop="id" label="编号"></el-table-column>
      <el-table-column label="卡号" prop="no" show-overflow-tooltip></el-table-column>
      <el-table-column label="卡密" prop="key" show-overflow-tooltip></el-table-column>
      <el-table-column label="链接" prop="link" show-overflow-tooltip></el-table-column>
      <el-table-column label="添加日期">
        <template #default="s">{{s.row.created_at | date}}</template>
      </el-table-column>
      <el-table-column label="发放日期" show-overflow-tooltip>
        <template #default="s">
          <span v-if="s.row.grant_uid > 0">{{s.row.grant_time | date}}</span>
        </template>
      </el-table-column >
      <el-table-column label="订单编号" prop="grant_order_id"></el-table-column>
      <el-table-column label="购买用户">
        <template #default="s">
          <div v-if="s.row.grant_uid">
            <el-avatar :src="s.row.u_avatar | tomedia" size="small"></el-avatar>
            <span>{{s.row.u_nickname}}（ID：{{s.row.grant_uid}}）</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column fixed="right" width="100" align="center" label="操作">
        <template #header>
          <div>
            <el-button style="margin-right: 10px" size="mini" @click="importExcelTpl">下载模版</el-button>
            <el-upload
                ref="uploader"
                :action="importExcelAction"
                :headers="{ytoken:vuex_token.data}"
                name="file"
                :show-file-list="false"
                accept=".xlsx"
                :on-success="success"
                :on-error="error"
                :limit="1"
            >
              <el-button type="primary" size="mini">导入卡密</el-button>
            </el-upload>
          </div>
        </template>
        <template #default="s">
          <el-button @click="del(s.row.id)" size="mini" type="danger">删除</el-button>
        </template>
      </el-table-column>
      <template #append>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
      </el-table>
    </template>
  </div>
</template>

<script>
import {requestWithUni} from "@/common/resquest";

const searchFormTpl = {
  no:"",
  nickname:"",
  uid:0,
  grant_state:0, //1未发放 2已发放
}

export default {
  name: "cdkey",
  model:{
    prop:"modelVal",
    event:'change'
  },
  props:{
    modelVal:{
      type:Object,
      default(){
        return{}
      }
    },
  },
  watch:{
    modelVal(){
      this.form = this.modelVal;
    },
  },
  data(){
    return{
      form:this.modelVal,

      searchForm:{...searchFormTpl},
      page:1,
      total:0,
      list:[],
      importExcelAction:"",
    }
  },
  mounted() {
    this.searchGo();
    this.importExcelAction =  requestWithUni(this.axios.defaults.baseURL +  this.$u.api.shop.goods.importExcelCdkeyAction ) + "&goods_id=" + this.form.id;
  },
  methods:{
    submit(){
      this.$emit('form-submit',this.form.id > 0);
    },
    pageChange(e){
      this.page = e;
      this.searchGo();
    },
    del(id){
      this.$u.api.shop.goods.cdkeyDel({id}).then(()=>{
        this.$message.success("操作成功");
        this.searchGo();
      })
    },
    searchGo(){
      this.load();
    },
    load(){
      //查询
      this.$u.api.shop.goods.cdkeySearch({
        goods_id:this.form.id,
        ...this.searchForm
      }).then(res=>{
        this.total = res.total;
        this.list = res.list;
      })
    },
    searchReset(){
      this.searchForm = {...searchFormTpl};
      this.page = 1;
      this.searchGo();
    },
    success(e){
      this.$refs.uploader.clearFiles();
      if (e.errno == 0){
        this.$message.success(`操作完成`);
        this.searchReset();
      }else {
        this.$message.error(e.message);
      }
    },
    error(e){
      console.log(e)
    },
    handleClose(done){
      done();
    },
    importExcelTpl(){
      location.href = location.origin + `/backend/shop/import-excel-goods-cdkey-tpl`;
    },

  }
}
</script>

<style scoped>

</style>