<template>
  <div>
    <el-form>
      <el-form-item label="库存数量">
        <el-input-number :disabled="form.spec" v-model="form.stock" :min="-1"></el-input-number>
      </el-form-item>
      <el-form-item label="商品规格">
        <el-switch v-model="form.spec"></el-switch>
      </el-form-item>
      <el-form-item v-if="form.spec">
        <div v-for="(v,k) in list" :key="k" class="spec-label-item">
          <div class="flex-def">
            <el-input
                @keyup.enter.native="LabelUpdate(v)"
                @blur="LabelUpdate(v)"
                v-model="v.title"
                style="width: 30rem">
            </el-input>
            <el-button @click="delSLI(v)" type="danger">X</el-button>
          </div>
          <div style="margin-top: .5rem">
            <el-tag
                :key="value.id"
                v-for="value in v.items"
                size="normal"
                closable
                :disable-transitions="false"
                @close="delSVI(value)">
              {{ value.title }}
            </el-tag>
            <el-input
                class="input-new-tag"
                v-if="inputValueShow && active_key === k"
                v-model="inputValue"
                :ref="`inputValue${k}`"
                size="small"
                @keyup.enter.native="addValueSubmit"
                @blur="addValueSubmit"
            >
            </el-input>
            <el-button v-else class="button-new-tag" size="mini" @click="addValue(v,k)">+ 添加规格值</el-button>
          </div>
        </div>
        <el-divider v-if="list.length > 0"></el-divider>
        <div class="y-desc" style="color: red">添加、修改规格项及规格值后请点击刷新规格项目表</div>
        <div class="flex-def">
          <div class="flex-def">
            <el-input
                @keyup.enter.native="addLabelSubmit"
                @blur="addLabelSubmit"
                v-model="inputLabel"
                ref="inputLabel"
                v-if="inputLabelShow">
            </el-input>
            <el-button v-else @click="addLabel">+添加规格项</el-button>
          </div>
          <el-button @click="handsRefreshOptions" style="margin-left: 1rem" v-if="list.length > 0" type="success">刷新规格项目表</el-button>
        </div>
      </el-form-item>
      <el-form-item v-if="form.spec">
        <el-table border v-if="options.length > 0" :data="options" :span-method="objectSpanMethod">
          <el-table-column align="center" :label="item.split('-')[0]" v-for="(item,key) in  options[0].title.split(';')" :key="key">
            <template #default="s">{{ (s.row.title.split(';')[key]).split('-').pop() }}</template>
          </el-table-column>
          <el-table-column prop="stock" align="center">
            <template #header>
              <div class="flex-def flex-cCenter flex-zCenter">
                库存
                <el-input-number v-model="global_edit.stock"></el-input-number>
                <el-button @click="setOptionGlobal" icon="el-icon-arrow-down"></el-button>
              </div>
            </template>
            <template #default="s">
              <el-input-number @change="editThis(s.row)" v-model="s.row.stock"></el-input-number>
            </template>
          </el-table-column>
          <el-table-column prop="fee" align="center">
            <template #header>
              <div class="flex-def flex-cCenter flex-zCenter">
                售价
                <el-input-number v-model="global_edit.fee"></el-input-number>
                <el-button @click="setOptionGlobal" icon="el-icon-arrow-down"></el-button>
              </div>
            </template>
            <template #default="s">
              <el-input-number @change="editThis(s.row)" v-model="s.row.fee"></el-input-number>
            </template>
          </el-table-column>
          <el-table-column prop="original_price" align="center">
            <template #header>
              <div class="flex-def flex-cCenter flex-zCenter">
                标价
                <el-input-number v-model="global_edit.original_price"></el-input-number>
                <el-button @click="setOptionGlobal" icon="el-icon-arrow-down"></el-button>
              </div>
            </template>
            <template #default="s">
              <el-input-number @change="editThis(s.row)" v-model="s.row.original_price"></el-input-number>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item>
        <el-button @click="$emit('form-submit')" type="primary">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "spec",
  model: {
    prop: "modelVal",
    event: 'change'
  },
  props: {
    modelVal: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  watch: {
    modelVal() {
      this.form = this.modelVal;
    },
  },
  data() {
    return {
      form: this.modelVal,
      list: [],
      inputLabelShow: false,
      inputLabel: "",
      goods_id: false,
      inputValueShow: false,
      inputValue: "",
      active_spec_id: false,
      active_key: false,
      options: [],
      options_log:{},
      global_edit:{
        goods_id:false,
        stock:0,
        fee:0,
        original_price:0,
      },
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.goods_id = parseInt(this.$route.query.id)
      this.loadSpec();
      this.loadOptions();
    }
  },
  methods: {
    setOptionGlobal(){
      this.global_edit.goods_id = this.goods_id;
      this.$u.api.shop.goods.specOptionEditGlobal(this.global_edit).then(()=>{
        this.global_edit.fee = 0;
        this.global_edit.stock = 0;
        this.global_edit.original_price = 0;
        this.loadOptions();
        this.$emit("reload",this.$route.query.id);
      })
    },
    editThis(e){
      this.$u.api.shop.goods.specOptionEdit(e).then(()=>{
        this.loadOptions();
        this.$emit("reload",this.$route.query.id);
      })
    },
    handsRefreshOptions(){
      let loading = this.$loading({
        text:"请稍候..."
      })
      this.$u.api.shop.goods.specOptionRefresh({id:this.goods_id}).then(()=>{
        loading.close();
        this.$message.success("操作成功")
        this.loadOptions();
        this.$emit("reload",this.$route.query.id)
      }).catch(()=>{
        loading.close();
      })
    },
    objectSpanMethod({rowIndex, columnIndex}) {
      for (let i = 0; i < this.options_log.total_label-1; i++) {
        if (columnIndex === i && i < this.options_log.total_label-1) {
          let rowSpan = 1;
          let computeIndex = this.options_log.total_label-1
          for (let j = i+1; j < this.options_log.total_label; j++) {
            rowSpan *= this.options_log.content[computeIndex]
            computeIndex--
          }
          if (rowIndex % rowSpan === 0) {
            return {
              rowspan: rowSpan,
              colspan: 1
            };
          } else {
            return {
              rowspan: 0,
              colspan: 0
            };
          }
        }
      }
    },
    loadOptions() {
      this.$u.api.shop.goods.specOption({id: this.goods_id}).then(res => {
        this.options = res.list ? res.list : [];
        res.log.content = res.log.content.split(",")
        this.options_log = res.log;
      })
    },
    addValue({id}, k) {
      this.active_key = k;
      this.active_spec_id = id
      this.inputValueShow = true;
      this.$nextTick(() => {
        this.$refs[`inputValue${k}`][0].$refs.input.focus();
      });
    },
    addValueSubmit() {
      let inputValue = this.inputValue;
      if (inputValue && this.active_spec_id) {
        this.$u.api.shop.goods.specItemEdit({
          goods_id: this.goods_id,
          spec_id: this.active_spec_id,
          title: inputValue,
        }).then(() => {
          this.loadSpec();
        })
      }
      this.inputValueShow = false;
      this.inputValue = "";
    },


    loadSpec() {
      this.$u.api.shop.goods.specSearch({id: this.goods_id}).then(res => {
        this.list = res;
      })
    },
    addLabel() {
      this.inputLabelShow = true;
      this.$nextTick(() => {
        this.$refs.inputLabel.$refs.input.focus();
      });
    },
    LabelUpdate(item) {
      if (item.title) {
        this.$u.api.shop.goods.specEdit(item).then(() => {
          this.loadSpec();
        })
      }
    },
    addLabelSubmit() {
      let value = this.inputLabel;
      if (value) {
        this.$u.api.shop.goods.specEdit({goods_id: this.goods_id, title: value}).then(() => {
          this.loadSpec();
        })
      }
      this.inputLabelShow = false;
      this.inputLabel = "";
    },
    delSLI(v) {
      this.$u.api.shop.goods.specDel({id: v.id}).then(() => {
        this.loadSpec();
      })
    },
    delSVI(item) {
      this.$u.api.shop.goods.specItemDel({id: item.id}).then(() => {
        this.loadSpec();
      })
    },
  }
}
</script>

<style scoped>
.spec-label-item:nth-child(2n) {
  background-color: #f8f8f8;
}

.spec-label-item {
  padding: .5rem;
}

.spec-label-item + .spec-label-item {
  margin-top: 1rem;
}


.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>